import { useCallback, useEffect, useState } from "react";
import platform from "../../../system/platform";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";
//import { ApplePay, GooglePay } from "@square/web-sdk";
import ValeSystem, { useValeContext } from "../../../system/ValeSystem";
import { ApplePay, GooglePay } from "@square/web-payments-sdk-types";
import { useCartContext } from "../../../system/CartProvider";
import { useSubmitOrder } from "./checkout_process";

type Props = {
  busy?: boolean;
  onBusy?: (busy: boolean) => void;
  onPaymentProcess: (paymentId: string) => void
};

const createPaymentRequest = (vale: ValeSystem, cost: number) => {
  const amount = (cost / 100.0).toFixed(2);
  const paymentRequest = vale?.squarePaymentsClient!.paymentRequest({
    countryCode: "US",
    currencyCode: "USD",
    total: {
      amount,
      label: "Total",
    },
  });
  return paymentRequest;
};

export const ApplePayButton: React.FC<Props> = ({
  onBusy,
  onPaymentProcess
}) => {
  const [applePay, setApplePay] = useState<ApplePay | undefined>(undefined);
  const vale = useValeContext();
  const cart = useCartContext();

  const submitOrder = useSubmitOrder();

  const onApplePay = useCallback(async () => {
    try {
      if (!applePay) {
        console.warn("Apple pay not initialized");
        return
      }

      onBusy?.(true);
      console.log("triggering apple pay tokenize");
      const token = await applePay!.tokenize();

      onPaymentProcess(token?.token!);
      console.log("triggering apple tokenize complete");
    } catch (e) {
      console.warn("apple pay error", e);
    } finally {
      onBusy?.(false);
    }
  }, [submitOrder, applePay, onPaymentProcess]);

  useEffect(() => {
    const genPaymentRequest = async () => {
      try {

        if (!vale || !cart?.finalCost) return;

        var finalCost = cart.finalCost;

        const paymentRequest = createPaymentRequest(vale, finalCost);
        const applePay = await vale?.squarePaymentsClient!.applePay(
          paymentRequest!,
        );

        setApplePay(applePay);
      } catch (e: any) {
        debugger
        if (e?.name === "PaymentMethodUnsupportedError") {
          console.info("Apple pay not supported on this platform");
        } else {
          console.warn("apple pay error", e);
        }
      }
    };
    genPaymentRequest();
  }, [vale, cart?.finalCost]);

  return (
    <img onClick={onApplePay} src="/images/applepay.png" />
  );
};

export const GooglePayButton: React.FC<Props> = ({
  onBusy,
  onPaymentProcess
}) => {
  const vale = useValeContext();
  const cart = useCartContext();
  const [googlePay, setGooglePay] = useState<GooglePay | undefined>(undefined);

  const submitOrder = useSubmitOrder();

  useEffect(() => {
    const genPaymentRequest = async () => {
      try {
        if (!vale || !(cart?.finalCost)) return;

        const paymentRequest = createPaymentRequest(vale, cart.finalCost);
        const googlePay =
          await vale?.squarePaymentsClient!.googlePay(paymentRequest);

        googlePay.attach("#vale-google-pay-wrapper", {
          buttonColor: "white",
          buttonType: "short",
          buttonSizeMode: "fill"
        });
        setGooglePay(googlePay);
      } catch (e: any) {
        if (e?.name === "PaymentMethodUnsupportedError") {
          console.info("Google pay not supported on this platform");
        } else {
          console.warn("Google pay error", e);
        }
      }
    };
    genPaymentRequest();
  }, [vale, cart?.finalCost]);

  const onGooglePay = useCallback(async () => {
    onBusy?.(true);
    try {
      const token = await googlePay!.tokenize();
      onPaymentProcess(token?.token!)
    } catch (e) {
      console.warn("google pay error", e);
    } finally {
      onBusy?.(false);
    }
  }, [googlePay, onPaymentProcess]);

  // return (
  //   <div onClick={onGooglePay} style={{backgroundColor: "white", width: "148px", height: "54px", borderWidth: 2, borderRadius: 7, borderColor: "black", borderStyle: "solid"}} id="google-pay-button">
  //   </div>
  // );

  return <ValeLaunchButton backgroundColor="white" height={56} width={159} onPress={onGooglePay}>
    <div id="vale-google-pay-wrapper" style={{ height: 56 }} />
  </ValeLaunchButton>
};

const DigitalWalletPayment: React.FC<Props> = (props) => {
  const platformName = platform.browserInfo.platform?.toLocaleLowerCase();

  if (platformName === "iphone") {
    return <ApplePayButton {...props} />;
  }

  if (platformName === "android") {
    return <GooglePayButton {...props} />;
  }

  return null;
};

export default DigitalWalletPayment;
