import React, { useCallback, useMemo, useRef } from 'react'
import { useValeContext } from '../../../../../system/ValeSystem';
import { useCartContext } from '../../../../../system/CartProvider';
import { useNoSessionContext } from '../../../../../system/NoSessionProvider';
import { useSessionContext } from '../../../../../system/SessionProvider';
import { MenuItem } from '../../../../../vale_common';
import SelectionItem from './SelectionItem';
import { getOverrideImage, orderMenuOverride } from './menu_overrides';
import { getMenuItemPrice } from '../../../order/menu_utils';
import { DrinkNameEntryModal, DrinkNameEntryModalHandle } from './DrinkNameEntry';
import { getEnv } from '../../../../../system/env';
import { getStoreStatus } from '../../../../../system/menu/menuUtils';
import { features } from 'process';

const env = getEnv()

export type SelectionViewProps = {
    onAdd: (menuItem: MenuItem) => Promise<boolean>
    hideFree?: boolean
    hidePrice?: boolean
}

const SelectionView: React.FC<SelectionViewProps> = (props) => {
    const vale = useValeContext();
    const cart = useCartContext();
    const noSessionInfo = useNoSessionContext();
    const session = useSessionContext();
    const drinkNameModalRef = useRef<DrinkNameEntryModalHandle>(null);

    const storeIsClosed = useMemo(() => {
        const storeStatusInfo = getStoreStatus(noSessionInfo?.locations);

        return storeStatusInfo?.storeStatus != "OPEN"
    }, [noSessionInfo?.locations]);

    const onStoreClosed = useMemo(() => {
        //@ts-ignore
        return storeIsClosed ? () => navigation.navigate("hours") : undefined
    }, [storeIsClosed]);

    const onPress = useCallback(async (menuItem: MenuItem) => {
        if (!(cart!.orderName) && !session?.userData?.userRecord?.displayName) {
            //If no name entry, go get it.
            //We auto populate with the users name if they are logged in.
            const name = await drinkNameModalRef.current?.getName(undefined);
            if (!name) {
                return false
            }

            cart?.setOrderName?.(name);
        }

        return props.onAdd(menuItem)
        //cart?.addItem({ itemTypeId: menuItem.id!, itemTypeVariationId: menuItem.variations![0].id!, price: menuItem.variations?.[0].price || {}, name: menuItem.name! });
    }, [drinkNameModalRef, cart!.orderName, cart?.setOrderName, cart?.addItem, vale?.valeClientOpenapi])


    if (!noSessionInfo?.menuItems) return null;

    var orderedList = orderMenuOverride(Array.from(noSessionInfo?.menuItems?.values()!))

    orderedList = orderedList.filter(menuItem => {
        return !menuItem.isVibesItem
    })

    if (props.hideFree) {
        orderedList = orderedList.filter(menuItem => {
            const price = getMenuItemPrice(menuItem)
            return !(!price || !price.amount)
        })
    }

    const menuItems = orderedList.map(
        (menuItem, index) => {
            const imageUrl = getOverrideImage(menuItem.name) ?? menuItem.imageUrl;
            const notAvailable = ((menuItem?.variations?.map(v => v.absentAtLocationIds?.includes(env.squareDefaultLocationId) || false).filter(v => !!v)) || []).length > 0
            const ordered = cart?.items.find((item) => item.itemTypeId === menuItem.id);

            return (
                <SelectionItem
                    key={menuItem.id}
                    iconUrl={imageUrl}
                    onPress={() => onPress(menuItem)}
                    name={menuItem.name!}
                    description={menuItem.description!}
                    price={getMenuItemPrice(menuItem)}
                    notAvailable={notAvailable}
                    ordered={!!ordered}
                    hidePrice={props.hidePrice}
                    onStoreClosed={onStoreClosed}
                />
            );
        },
    );


    const FeatureItems = orderedList.map(
        (menuItem, index) => {



            if(menuItem.name! == "Matcha" || menuItem.name! == "Vanilla") {

                const imageUrl = getOverrideImage(menuItem.name) ?? menuItem.imageUrl;
                const notAvailable = ((menuItem?.variations?.map(v => v.absentAtLocationIds?.includes(env.squareDefaultLocationId) || false).filter(v => !!v)) || []).length > 0
                const ordered = cart?.items.find((item) => item.itemTypeId === menuItem.id);

                return (
                    <SelectionItem
                        key={menuItem.id}
                        iconUrl={imageUrl}
                        onPress={() => onPress(menuItem)}
                        name={menuItem.name!}
                        description={menuItem.description!}
                        price={getMenuItemPrice(menuItem)}
                        notAvailable={notAvailable}
                        ordered={!!ordered}
                        hidePrice={props.hidePrice}
                        onStoreClosed={onStoreClosed}
                    />
                );
            }
        },
    );

    return <div style={{marginTop: "30px"}}>
        
        <h2 style={{textAlign:"center", marginBottom:"-10px", fontFamily:"Inter", fontSize:"1.5em", color:"white"}}>MOST POPULAR</h2>
        <div className="selection-page-flex-container">
            
            <div className="selection-page-grid-container">
                {FeatureItems}
            </div>
        </div>


        <h2 style={{textAlign:"center", marginTop:"-20px", marginBottom:"-10px", fontFamily:"Inter", fontSize:"1.5em", color:"white"}}>FULL MENU</h2>
            <div className="selection-page-flex-container">

                <div className="selection-page-grid-container">
                    {menuItems}
                </div>

                <DrinkNameEntryModal ref={drinkNameModalRef} />
            </div>


        </div>

}

export default SelectionView